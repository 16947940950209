import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const dev: IPartnerAppSettings = {
  cdnBaseUrl: 'http://localhost:5003',
  serviceEndPoints: [
    {
      clientId: 'api://f77695f8-032a-43b9-9263-418c7efa2c56',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/csp-api',
    },
    {
      clientId: 'api://f77695f8-032a-43b9-9263-418c7efa2c56',
      serviceEndPoint: 'https://fd-csp-sit-h7f5bmcpfahwfwfr.z01.azurefd.net/csp-api',
    },
    // NOTE: Uncomment this when running in local (uppdate the endpoint if needed)
    // {
    //   clientId: 'api://f77695f8-032a-43b9-9263-418c7efa2c56',
    //   serviceEndPoint: 'http://localhost:7071/api',
    // },
  ],
  PortfolioAppConfiguration: {
    'portfolioServiceBaseUri': 'https://professionalservicesint.microsoft.com/csp-api',
    'subscriptionKey': '92af6c717f5b4f2c9a41db17e17cc947',
    'RMSericeBaseUri': 'https://professionalservicesint.microsoft.com/rmsvcsit/',
  
    'RMAPIMSubscriptionKey': '01e62d14a5f04161818c5ef9ee321875',
    'CspGraphQLBaseURL': 'https://fd-csp-sit-h7f5bmcpfahwfwfr.z01.azurefd.net/csp-api',
    'CspCsdrFunctionBaseUri': 'https://fd-csp-powerpoint-export-sit-dtb2abg9a3a5e5dg.z01.azurefd.net/api',
  }
};
