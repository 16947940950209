import { merge } from 'lodash';
import { baseAppSettings } from '../configs/appSettings';
import { aurorasit } from '../configs/appSettings.aurorasit';
import { dev } from '../configs/appSettings.dev';
import { prod } from '../configs/appSettings.prod';
import { sit } from '../configs/appSettings.sit';
import { uat } from '../configs/appSettings.uat';
import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';
import { Environments } from './environments';

export const appSettings = (): IPartnerAppSettings => {
  let appSetting;
  switch (window['FxpAppSettings'].EnvironmentName) {
    case Environments.Dev: appSetting = dev; break;
    case Environments.Sit: appSetting = sit; break;
    case Environments.Uat: appSetting = uat; break;
    case Environments.AuroraSit: appSetting = aurorasit; break;
    case Environments.Prod: appSetting = prod; break;
    default: appSetting = dev; break;
  }

    // properties in later spreads “win out” over previously created properties.
    const mergedSettings = merge(baseAppSettings, appSetting);

    return mergedSettings as IPartnerAppSettings;
};