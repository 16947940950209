import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: 'https://csp-ux-prod.azureedge.net/portfolio-ui',
  serviceEndPoints: [
    {
      clientId: 'api://2a5ea82d-af4c-494d-9d60-79db20d2ebd1',
      serviceEndPoint: 'https://professionalservices.microsoft.com/csp-api',
    },
    {
      clientId: 'api://2a5ea82d-af4c-494d-9d60-79db20d2ebd1',
      serviceEndPoint: 'https://fd-csp-prod-gdf7b6ambzheftcy.z01.azurefd.net/csp-api',
    },
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://flighting-api-prod.azurefd.net/'
    },
    {
      clientId: 'api://2a5ea82d-af4c-494d-9d60-79db20d2ebd1',
      serviceEndPoint: 'https://fd-csp-powerpoint-export-prod-hzdxcxg3drc9a6ed.z01.azurefd.net/api',
    }
  ],
  PortfolioAppConfiguration: {
    'portfolioServiceBaseUri': 'https://professionalservices.microsoft.com/csp-api',
    'RMSericeBaseUri': 'https://professionalservices.microsoft.com/rmsvc/',
    'subscriptionKey': '6ce775478d60402492136312fa60ccc8',
    'ConsultingProjectInVirtuoso': 'https://virtuoso.microsoft.com/ProjectDetail?ProjectId=%s',
    'FastTrackProjectInMSX': 'https://msxinsights.microsoft.com/User/report/d31702bb-28e1-4cab-9120-d3c3598b65b1?reportTab=ReportSection925dd04d5cc647b656e0',
    'RMAPIMSubscriptionKey': '01e62d14a5f04161818c5ef9ee321875',
    'CreateContactInMsxLink': 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=contact',
    'ObjectiveDetailsInMsx': 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_customerobjective&id=%s',
    'PriorityDetailsInMsx': 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&newWindow=true&pagetype=entityrecord&etn=msp_accountplanpriority&id=%s',
    'CspGraphQLBaseURL': 'https://fd-csp-prod-gdf7b6ambzheftcy.z01.azurefd.net/csp-api',
    'OpportunityDetailsInMsx': 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=opportunity&id=%s',
    'EcifDetailsInOneAskRequestPortal': 'https://oneask.microsoft.com/requests/all',
    'UnifiedActionTrackerDeepLink': 'https://uat-prod-webui-centus.azurewebsites.net/action?id=%s',
    'MilestoneDetailsInMsx': 'https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&forceUCI=1&pagetype=entityrecord&etn=msp_engagementmilestone&id=%s',
    'flightingServicePath': '/api/v1/featureflags/Evaluate?featureNames=%s',
    'featureFlighting': {
      'BaseUrl': 'https://esxp.microsoft.com',
      'ResourceId': 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      'RoleGroupId': '2',
      'TenantId': 'ES',
      'Environment': 'Prod',
      'Application': 'Customer Success Portfolio',
    },
    'SupportDeliveryEsxpLink': 'https://esxp.microsoft.com/#/supportdelivery/requestdetails/%s',
    'CspCsdrFunctionBaseUri': 'https://fd-csp-powerpoint-export-prod-hzdxcxg3drc9a6ed.z01.azurefd.net/api',
    'CspDetailsInEsxp': 'https://esxp.microsoft.com/#/customers/%s/csps/%s',
    'SupportProjectInRmexV2': 'https://esxp.microsoft.com/#/rm/customer/%s/supportproject/%s',
  }
};
