import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const uat: IPartnerAppSettings = {
  cdnBaseUrl: 'https://csp-ux-uat.azureedge.net/portfolio-ui',
  serviceEndPoints: [
    {
      clientId: 'api://b68ad24f-c1d9-4b37-b42f-9564a4609b2f',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/csp-uat-api',
    },
    {
      clientId: 'api://b68ad24f-c1d9-4b37-b42f-9564a4609b2f',
      serviceEndPoint: 'https://fd-csp-uat-gzajdgfvctb6cdew.z01.azurefd.net/csp-api',
    },    
    {
      clientId: 'api://b68ad24f-c1d9-4b37-b42f-9564a4609b2f',
      serviceEndPoint: 'https://fd-csp-powerpoint-export-uat-a5hbg8c3hkhrdgbt.z01.azurefd.net/api',
    }
  ],
  PortfolioAppConfiguration: {
    'portfolioServiceBaseUri': 'https://professionalservicesint.microsoft.com/csp-uat-api',
    'subscriptionKey': '92af6c717f5b4f2c9a41db17e17cc947',
    'CspGraphQLBaseURL': 'https://fd-csp-uat-gzajdgfvctb6cdew.z01.azurefd.net/csp-api',
    'SupportDeliveryEsxpLink' : 'https://servicesuat.microsoft.com/#/supportdelivery/requestdetails/%s',
    'CspCsdrFunctionBaseUri': 'https://fd-csp-powerpoint-export-uat-a5hbg8c3hkhrdgbt.z01.azurefd.net/api',
    'CspDetailsInEsxp': 'https://servicesuat.microsoft.com/#/customers/%s/csps/%s',
    'SupportProjectInRmexV2': 'https://servicesuat.microsoft.com/#/rm/customer/%s/supportproject/%s',
  }
};
