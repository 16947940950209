import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const aurorasit: IPartnerAppSettings = {
  cdnBaseUrl: '',
  serviceEndPoints: [
    {
      clientId: '',
      serviceEndPoint: '',
    },
  ],
  PortfolioAppConfiguration : {
    'portfolioServiceBaseUri': '',
    'graphApiBaseUri': '',
    'subscriptionKey' : '',
  }
};
